import React from 'react'

const Play = () => (
  <svg viewBox="0 0 69 69" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68 34.5C68 53.0015 53.0015 68 34.5 68C15.9985 68 1 53.0015 1 34.5C1 15.9985 15.9985 1 34.5 1C53.0015 1 68 15.9985 68 34.5ZM69 34.5C69 53.5538 53.5538 69 34.5 69C15.4462 69 0 53.5538 0 34.5C0 15.4462 15.4462 0 34.5 0C53.5538 0 69 15.4462 69 34.5ZM27 25L42.6226 34.1132L27 43.2264V25Z"
    />
  </svg>
)

export default Play
