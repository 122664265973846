import React from 'react'
import PropTypes from 'prop-types'

import ImageLoad from './ImageLoad'
import PlayFilled from '../../icons/PlayFilled'

const AlbumThumb = ({ src, title, artist, left, top, pad, speed, col, norepeat, url, large }) => (
  <li className="row">
    <div
      className={`${col ? 'w-100' : `cl-sm-2 os-sm-${left}`} ${top ? `mtn-sm-${top}` : ''} ${
        pad ? `py-sm-${pad}` : ''
      }`}
    >
      <div className="album-thumb" data-scroll data-scroll-speed={speed || '2'}>
        <a href={url} target="_blank" rel="noreferrer noopener">
          <div className="album-img img-hover">
            <div className="hover-clip">
              <div className="hover-scale">
                <ImageLoad src={src} alt={`${title} by ${artist}`} norepeat={norepeat} />
              </div>
            </div>
            {large ? (
              <div className="large-play">
                <span className="f5">Listen</span>
                <PlayFilled />
              </div>
            ) : (
              <PlayFilled />
            )}
          </div>
          {title ? <h5>{title}</h5> : ''}
          {artist ? <p className="f8">{artist}</p> : ''}
        </a>
      </div>
    </div>
  </li>
)

AlbumThumb.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  artist: PropTypes.string,
  left: PropTypes.string,
  top: PropTypes.string,
  pad: PropTypes.string,
  speed: PropTypes.string,
  col: PropTypes.bool,
  norepeat: PropTypes.bool,
  url: PropTypes.string,
  large: PropTypes.bool,
}

export default AlbumThumb
