import React from 'react'
import PropTypes from 'prop-types'

import Appear from './Appear'

const GraffitiWall = ({ delay }) => (
  <div className="abs top left z4 w100 h100 no-pointer">
    <Appear
      src="writer-1-pnut.png"
      alt="Graffiti Writing on a Train"
      width="5"
      top="0"
      left="0"
      delay={`${delay + 0.5}`}
      id="graffiti-wall"
    />
    <Appear
      src="writer-2-superkool223.png"
      alt="Graffiti Writing on a Train"
      width="7"
      top="0"
      left="5"
      delay={`${delay + 0.1}`}
      id="graffiti-wall"
    />
    <Appear
      src="writer-3.png"
      alt="Graffiti Writing on a Train"
      width="8"
      top="0"
      left="11"
      delay={`${delay + 0.6}`}
      id="graffiti-wall"
    />
    <Appear
      src="writer-4-phase.png"
      alt="Graffiti Writing on a Train"
      width="5"
      top="0"
      left="19"
      delay={`${delay + 0.3}`}
      id="graffiti-wall"
    />
    <Appear
      src="writer-5-stayhigh149.png"
      alt="Graffiti Writing on a Train"
      width="8"
      top="6"
      left="0"
      delay={`${delay + 0.8}`}
      id="graffiti-wall"
    />
    <Appear
      src="writer-6-tracy168wildstyle.png"
      alt="Graffiti Writing on a Train"
      width="7"
      top="5"
      left="5"
      delay={`${delay + 0.5}`}
      id="graffiti-wall"
    />
    <Appear
      src="writer-7-dondi.png"
      alt="Graffiti Writing on a Train"
      width="9"
      top="2"
      left="11"
      delay={`${delay + 0}`}
      id="graffiti-wall"
    />
    <Appear
      src="writer-8-kool131.png"
      alt="Graffiti Writing on a Train"
      width="8"
      top="6"
      left="17"
      delay={`${delay + 0.5}`}
      id="graffiti-wall"
    />
    <Appear
      src="writer-9-noc167.png"
      alt="Graffiti Writing on a Train"
      width="5"
      top="13"
      left="0"
      delay={`${delay + 0.6}`}
      id="graffiti-wall"
    />
    <Appear
      src="writer-10-billy167.png"
      alt="Graffiti Writing on a Train"
      width="8"
      top="10"
      left="4"
      delay={`${delay + 0}`}
      id="graffiti-wall"
    />
    <Appear
      src="writer-11-lee.png"
      alt="Graffiti Writing on a Train"
      width="11"
      top="7"
      left="11"
      delay={`${delay + 0.4}`}
      id="graffiti-wall"
    />
    <Appear
      src="writer-12.png"
      alt="Graffiti Writing on a Train"
      width="8"
      top="19"
      left="0"
      delay={`${delay + 0.9}`}
      id="graffiti-wall"
    />
    <Appear
      src="writer-13-seen.png"
      alt="Graffiti Writing on a Train"
      width="8"
      top="16"
      left="5"
      delay={`${delay + 0.7}`}
      id="graffiti-wall"
    />
    <Appear
      src="writer-14-butch-kase.png"
      alt="Graffiti Writing on a Train"
      width="7"
      top="17"
      left="12"
      delay={`${delay + 0.75}`}
      id="graffiti-wall"
    />
    <Appear
      src="writer-15-blade.png"
      alt="Graffiti Writing on a Train"
      width="6"
      top="15"
      left="18"
      delay={`${delay + 0.85}`}
      id="graffiti-wall"
    />
    <div className="rel h100 w100" />
  </div>
)

GraffitiWall.propTypes = {
  delay: PropTypes.number,
}

export default GraffitiWall
