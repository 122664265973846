import React from 'react'

const Play = () => (
  <svg viewBox="0 0 71 71" className="play-filled" xmlns="http://www.w3.org/2000/svg">
    <path
      className="play-circle"
      d="M51 31.5C51 40.0604 44.0604 47 35.5 47C26.9396 47 20 40.0604 20 31.5C20 22.9396 26.9396 16 35.5 16C44.0604 16 51 22.9396 51 31.5Z"
    />
    <path className="play-btn" d="M40.3771 31.1639L31.7705 26V36.3279L40.3771 31.1639Z" />
  </svg>
)

export default Play
