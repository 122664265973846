import React from 'react'
import PropTypes from 'prop-types'

import Image from './Elements/Image'

const RevealLayered = ({ bg, fg }) => {
  const { bgSrc, bgAlt } = bg
  const { fgSrc, fgAlt } = fg

  return (
    <div className="rel">
      <div className="rel z1 w100 h100 ch chi">
        <Image src={bgSrc} alt={bgAlt} />
      </div>
      <div className="abs top left fill z2 ch">
        <div className="container">
          <div className="row center-xxs middle-xxs fill abs">
            <div className="cl-sm-6 tc-xxs">
              <Image src={fgSrc} alt={fgAlt} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

RevealLayered.propTypes = {
  bg: PropTypes.object,
  fg: PropTypes.object,
}

export default RevealLayered
