import React from 'react'
import PropTypes from 'prop-types'

import Image from '../comps/Elements/Image'
import ImageLoad from '../comps/Elements/ImageLoad'
import AlbumThumb from '../comps/Elements/AlbumThumb'
import AlbumList from '../comps/Elements/AlbumList'
import VideoThumb from '../comps/Elements/VideoThumb'
import PosterHover from '../comps/PosterHover'
import Fader from '../comps/Fader'

import NewEra from '../comps/NewEra'
import Horizontal from '../comps/Horizontal'
import Appear from '../comps/Appear'

import loLifesL from '../images/lo-lifes-left.jpg'
import loLifesR from '../images/lo-lifes-right.jpg'

import roxyPoster from '../images/roxy-poster.jpg'
import latinQuarterPoster from '../images/latin-quarter-poster.jpg'
import unionSquarePoster from '../images/union-square-poster.jpg'
import feverPoster from '../images/fever-poster.jpg'
import radiotronPoster from '../images/radiotron-poster.jpg'
import warehousePoster from '../images/warehouse-poster.jpg'
import paradisePoster from '../images/paradise-poster.jpg'
import musicBoxPoster from '../images/music-box-poster.jpg'

const Eighties = ({ x, y }) => (
  <>
    <div className="section" data-scroll-section>
      <div className="rel">
        <div id="intro-80s" className="abs top left z5 w100" data-scroll data-scroll-call="new-era">
          <div className="rel z2" data-scroll data-scroll-sticky data-scroll-target="#intro-80s">
            <div className="bg-blue-lt texture rel white">
              <div className="gap bg-blue-lt texture" />
              <NewEra
                bg={{
                  bgSrc: 'hero-mainstream-bg.jpg',
                  bgAlt: 'Birth of Hip Hop',
                }}
                fg={{
                  fgSrc: 'hero-mainstream-fg.png',
                  fgAlt: 'Birth of Hip Hop',
                }}
                chapter="Chapter 03"
                title="The Mainstream"
                year={{
                  yearSrc: 'year-80s.png',
                  yearAlt: "80's",
                }}
              />
            </div>
          </div>
          <div className="rel h200 w100" />
        </div>

        <div id="punk" className="abs top left z2 w100 bg-grey texture">
          <div
            className="abs top left w100 h100 z1"
            data-scroll
            data-scroll-sticky
            data-scroll-target="#punk"
          >
            <div className="rel w100 h100 bg-grey texture" />
          </div>
          <div className="rel z2" data-scroll data-scroll-sticky data-scroll-target="#punk">
            <div className="pb-sm-4">
              <div className="rel ch h100">
                <div className="container">
                  <div className="row">
                    <div className="cl-sm-12">
                      <h2 className="tc-xxs white rel z2 multiply">Hip-Hop & Punk</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Appear
            src="punk-1.png"
            alt="by Terise Slotkin Photography"
            col="6"
            pad="0"
            delay="4"
            id="punk"
          />
          <Appear src="punk-2.png" alt="by Bobby Grossman" col="6" pad="2" delay="5" id="punk" />
          <Appear
            src="punk-3.png"
            alt="by Paul Bruinooge/Patrick McMullan/Getty Images"
            col="6"
            pad="4"
            delay="6"
            id="punk"
          />

          <div className="rel h900 w100" />
        </div>

        <div className="abs top left z4 w100 no-pointer">
          <div className="rel h400 w100" />
          <div id="graffiti">
            <div className="rel z2" data-scroll data-scroll-sticky data-scroll-target="#graffiti">
              <div className="rel z2">
                <div className="side-paste">
                  <div
                    className="rel"
                    data-scroll
                    data-scroll-direction="horizontal"
                    data-scroll-target="#graffiti"
                    data-scroll-speed="7"
                  >
                    <Image src="graffiti-1.png" alt="Subway Graffiti" />
                  </div>
                  <div
                    className="rel"
                    data-scroll
                    data-scroll-direction="horizontal"
                    data-scroll-target="#graffiti"
                    data-scroll-speed="8"
                  >
                    <Image src="graffiti-2.png" alt="Subway Graffiti" />
                  </div>
                  <div
                    className="rel"
                    data-scroll
                    data-scroll-direction="horizontal"
                    data-scroll-target="#graffiti"
                    data-scroll-speed="9"
                  >
                    <Image src="graffiti-3.png" alt="Subway Graffiti" />
                  </div>
                  <div
                    className="rel"
                    data-scroll
                    data-scroll-direction="horizontal"
                    data-scroll-target="#graffiti"
                    data-scroll-speed="10"
                  >
                    <Image src="graffiti-4.png" alt="Subway Graffiti" />
                  </div>
                </div>
              </div>
            </div>
            <div className="rel h900 w100" />
          </div>
          <div className="rel h1000 w100" />
        </div>

        <div className="abs top left z1 w100">
          <div className="rel h900 w100" />
          <div id="kurtis" className="bg-pink-lt">
            <div className="rel h1000 w100">
              <div data-scroll data-scroll-sticky data-scroll-target="#kurtis">
                <div className="bg-pink-lt">
                  <div className="rel ch h100">
                    <div className="rel w-100">
                      <h3 className="f-banner tc-xxs">
                        <div
                          data-scroll
                          data-scroll-direction="horizontal"
                          data-scroll-target="#kurtis"
                          data-scroll-speed="-2.2"
                        >
                          Kurtis
                        </div>
                        <div
                          data-scroll
                          data-scroll-direction="horizontal"
                          data-scroll-target="#kurtis"
                          data-scroll-speed="2.2"
                        >
                          Blow
                        </div>
                      </h3>
                      <div className="abs top left fill">
                        <div className="ch">
                          <div className="container">
                            <div className="row center-xxs">
                              <div className="cl-sm-3">
                                <ul className="pt-sm-12">
                                  <AlbumThumb
                                    src="kurtis-blow.jpg"
                                    left="0"
                                    col
                                    norepeat
                                    large
                                    url="https://www.youtube.com/watch?v=qzl-2g5HhaI"
                                  />
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rel h1900 w100 bg-pink-lt" />
      </div>
    </div>
    <div className="section rel bg-pink-lt" data-scroll-section>
      <div className="bg-pink-lt gap" />
      <div className="pt-sm-2 pb-sm-2" data-scroll data-scroll-speed="2" data-scroll-delay="0.2">
        <div className="container">
          <div className="row">
            <div className="cl-sm-5 os-sm-1">
              <h4 className="line-s pb-sm-4">
                <span>1980</span> The Breaks
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="cl-sm-3 os-sm-1">
              <p>
                Kurtis Blow becomes the first rapper to be signed to a major label and the first
                rapper on U.S. TV (Oct. 1980 Soul Train appearance). His single, “The Breaks,”
                becomes the first rap song to be officially certified gold.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="battles">
        <div className="container">
          <div className="row center-xxs">
            <div className="cl-sm-10">
              <div
                className="bt bw-4 pt-sm-2"
                data-scroll
                data-scroll-speed="3"
                data-scroll-delay="0.8"
              >
                <div className="row">
                  <div className="cl-xxs-12">
                    <h4 className="line-s tc-xxs">
                      <span>1981</span>
                      <div>Cold Crush Brothers</div>
                      <div>
                        <span className="di f8 solid">vs </span> Fantastic Romantic 5
                      </div>
                    </h4>
                  </div>
                </div>
                <div className="row center-xxs">
                  <div className="cl-sm-10">
                    <Image
                      src="cold-vs-fantastic.png"
                      alt="Cold Crush Brothers vs Fantastic Romantic 5"
                    />
                  </div>
                </div>
                <div className="row center-xxs">
                  <div className="cl-sm-4">
                    <p className="tc-xxs">
                      One of the most famous old-school emcee battles: the Cold Crush Brothers take
                      on the Fantastic 5 at Harlem World.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="bt bw-4 pt-sm-2 pb-sm-6"
                data-scroll
                data-scroll-speed="4"
                data-scroll-delay="1"
              >
                <div className="row">
                  <div className="cl-xxs-12">
                    <h4 className="line-s tc-xxs pb-sm-4">
                      <span>1981</span>
                      <div>Rock Steady Crew </div>
                      <div>
                        <span className="di f8 solid">vs </span> Dynamic Rockers
                      </div>
                    </h4>
                  </div>
                </div>
                <div className="row center-xxs">
                  <div className="cl-sm-4 os-sm-2">
                    <p>
                      ABC’s 20/20 news program covers the Lincoln center battle between Rock Steady
                      Crew and the Dynamic Rockers, the first instance of national network coverage
                      of hip-hop.
                    </p>
                  </div>
                </div>
                <div className="row center-xxs">
                  <div className="cl-sm-10">
                    <div className="mtn-sm-8">
                      <Image
                        src="rock-steady-dynamic.png"
                        alt="Rock Steady Crew vs the Dynamic Rockers"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bt bw-4 pt-sm-4 pb-sm-6">
                <div className="row">
                  <h4 className="line-s f1">
                    <span>The Club Era—</span>
                  </h4>
                  <PosterHover
                    x={x}
                    y={y}
                    items={[
                      {
                        img: roxyPoster,
                        title: 'The Roxy',
                        date: 'NY',
                      },
                      {
                        img: latinQuarterPoster,
                        title: 'Latin Quarter',
                        date: 'NY',
                      },
                      {
                        img: unionSquarePoster,
                        title: 'Union Square',
                        date: 'NY',
                      },
                      {
                        img: feverPoster,
                        title: 'The Fever',
                        date: 'NY',
                      },
                      {
                        img: radiotronPoster,
                        title: 'RadioTron',
                        date: 'LA',
                      },
                      {
                        img: warehousePoster,
                        title: 'The Warehouse',
                        date: 'NY',
                      },
                      {
                        img: paradisePoster,
                        title: 'Paradise Garage',
                        date: 'NY',
                      },
                      {
                        img: musicBoxPoster,
                        title: 'Music Box',
                        date: 'NY',
                      },
                    ]}
                  />
                  <h4 className="line-s f1">
                    <span className="di">& More</span>
                  </h4>
                </div>
              </div>
              <div className="bt bw-4 pt-sm-2">
                <div className="row">
                  <div className="cl-sm-12">
                    <h4 className="line-s">
                      <span>1982</span> Wild Style
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="cl-sm-4">
                    <p>
                      Charlie Ahearn directs and produces Wild Style, regarded as the first major
                      hip-hop motion picture.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="wild-style" className="rel z1 mtn-sm-20 pb-sm-20">
          <div data-scroll data-scroll-call="expand" expand-multiply="2" expand-loc="center">
            <div data-scroll data-scroll-sticky data-scroll-target="#wild-style">
              <div className="expander rel expand-top" data-scroll data-scroll-call="appear">
                <div className="abs fill">
                  <Image
                    src="wild-style-poster-bw.png"
                    alt="Wild Style Poster in Black and White"
                  />
                </div>
                <div className="appearer">
                  <Image src="wild-style-poster.png" alt="Wild Style Poster" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="section bg-blue-lt white" data-scroll-section>
      <div id="cocaine" className="rel z1">
        <div className="abs fill z3">
          <div
            className="abs top left fill z1"
            data-scroll
            data-scroll-call="fader"
            appear-delay="1"
          >
            <div data-scroll data-scroll-sticky data-scroll-target="#cocaine">
              <div className="appearer">
                <div className="rel bg-white w100 h100" />
              </div>
            </div>
          </div>
          <div
            className="abs top left fill z2"
            data-scroll
            data-scroll-call="fader"
            appear-delay="2.5"
          >
            <div data-scroll data-scroll-sticky data-scroll-target="#cocaine">
              <div className="appearer">
                <div className="container ch h100 rel z2">
                  <div className="row center-xxs black">
                    <div className="cl-sm-8">
                      <h4 className="line-s pb-sm-4">
                        <span>Mid 80's</span>
                      </h4>
                      <h5 className="f4">
                        Crack cocaine use reaches epidemic proportions in Los Angeles, Miami, and
                        New York City.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="abs top left fill z1"
            data-scroll
            data-scroll-call="fader"
            appear-delay="2.6"
          >
            <div data-scroll data-scroll-sticky data-scroll-target="#cocaine">
              <div
                data-scroll
                data-scroll-speed="1"
                data-scroll-direction="horizontal"
                data-scroll-target="#cocaine"
              >
                <div className="appearer double-width-img">
                  <Image src="cocaine-texture.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="abs top left fill z4"
            data-scroll
            data-scroll-call="fader"
            appear-delay="6"
          >
            <div data-scroll data-scroll-sticky data-scroll-target="#cocaine">
              <div className="appearer">
                <div className="rel bg-pink w100 h100" />
              </div>
            </div>
          </div>
        </div>
        <div className="rel h800 w100" />
      </div>
    </div>
  </>
)

Eighties.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
}

export default Eighties
