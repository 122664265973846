import React from 'react'
import PropTypes from 'prop-types'

import AlbumThumb from './AlbumThumb'

const AlbumList = ({ albums }) => (
  <ul className="stagger">
    {albums.map((album, i) => (
      <AlbumThumb
        key={i}
        src={album.src}
        title={album.title}
        artist={album.artist}
        left={album.left}
        speed={album.speed}
        url={album.url}
      />
    ))}
  </ul>
)

AlbumList.propTypes = {
  albums: PropTypes.array,
}

export default AlbumList
