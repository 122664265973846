import React from 'react'

const Fun = () => (
  <svg viewBox="0 0 900 902" xmlns="http://www.w3.org/2000/svg" id="fun-svg">
    <path
      d="M836.706 426.936L835.98 416.588L801.604 418.996L799.62 390.695L895.973 383.946L898.56 420.893C900.232 444.734 892.523 460.682 870.442 462.226C848.991 463.729 838.321 449.991 836.706 426.936ZM880.222 421.915L879.634 413.53L854.169 415.314L854.783 424.093C855.279 431.17 860.841 434.989 868.494 434.457C876.148 433.915 880.774 429.774 880.222 421.915Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M803.92 471.183L899.389 485.857L889.077 552.965L869.062 549.887L874.946 511.596L857.917 508.978L852.631 543.373L832.866 540.336L838.153 505.936L820.126 503.165L813.746 544.702L793.112 541.532L803.92 471.183Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M772.361 594.645L787.616 595.755L796.87 572.44L784.907 563.027L794.549 538.735L873.474 601.717L862.477 629.424L761.993 620.762L772.361 594.645ZM838.469 601.952L812.56 584.041L807.135 597.713L838.469 601.952Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M736.881 653.011C752.75 630.135 773.363 622.701 802.713 643.061C833.613 664.495 835.095 686.624 819.605 708.962C802.315 733.888 786.712 732.333 777.448 726.709C773.992 724.633 771.134 721.688 769.698 719.571L781.972 697.238C783.399 698.711 786.246 701.001 788.598 702.474C794.948 706.559 800.218 705.582 804.528 699.14C809.441 691.839 805.914 682.359 786.175 668.667C767.78 655.905 757.898 654.483 751.855 662.761C746.411 670.17 747.745 676.853 755.214 682.191C757.591 683.842 760.327 685.417 762.336 686.491L747.08 706.278C745.439 705.777 741.043 704.166 736.748 700.868C725.107 691.834 722.209 674.163 736.881 653.011Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M711.34 689.539L776.058 761.248L725.665 806.738L712.097 791.706L740.854 765.748L729.31 752.955L703.477 776.275L690.078 761.427L715.91 738.107L703.692 724.568L672.501 752.725L658.514 737.227L711.34 689.539Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M628.064 740.929C628.494 736.757 631.05 732.252 633.785 728.985C635.62 726.746 638.657 724.184 640.487 723.146L645.82 731.383C644.674 732.273 643.447 733.515 642.532 735.008C641.208 737.069 640.605 740.004 642.517 742.959L643.749 744.861L653.667 738.439L666.039 757.551L643.882 771.898L632.057 753.635C629.465 749.616 627.497 746.093 628.064 740.929Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M594.501 773.073L626.116 864.345L598.571 873.891L573.627 801.869L539.011 813.864L532.345 794.609L594.501 773.073Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M500.469 897.682C470.582 901.016 452.3 886.346 448.609 853.22C444.611 817.342 461.896 801.747 489.564 798.664C518.276 795.463 537.457 807.499 541.399 842.876C545.156 876.627 528.659 894.537 500.469 897.682ZM491.584 816.79C480.49 818.027 476.129 825.093 478.885 849.845C481.656 874.72 488.675 880.773 498.593 879.669C509.293 878.478 513.853 870.752 511.118 846.251C508.373 821.621 501.241 815.711 491.584 816.79Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M390.113 797.386L418.753 801.164L436.983 901L406.912 897.037L398.001 838.013L376.059 892.973L347.42 889.199L390.113 797.386Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M359.081 791.75L325.257 882.234L261.67 858.459L268.761 839.49L305.043 853.055L311.076 836.913L278.479 824.729L285.483 805.995L318.08 818.179L324.465 801.097L285.105 786.382L292.416 766.824L359.081 791.75Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M275.279 741.634C279.185 740.1 284.359 740.315 288.515 741.245C291.347 741.854 295.013 743.372 296.771 744.528L291.894 753.041C290.58 752.432 288.909 751.911 287.165 751.783C284.727 751.548 281.843 752.356 280.09 755.413L278.965 757.376L289.22 763.251L277.907 783.008L255.003 769.883L265.821 751.001C268.198 746.859 270.427 743.495 275.279 741.634Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M203.88 698.926C225.74 720.707 223.102 736.01 208.987 750.178L162.827 796.512L142.173 775.932L187.091 730.846C193.573 724.342 196.544 719.311 189.846 712.639C182.219 705.036 176.779 709.382 171.186 714.996L126.269 760.082L105.62 739.502L151.78 693.168C165.895 679.005 183.139 678.254 203.88 698.926Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M140.052 615.922L112.307 668.294L155.803 642.765L168.364 664.173L85.0631 713.068L71.9038 690.643L100.339 637.713L56.0861 663.687L43.525 642.279L126.831 593.384L140.052 615.922Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M124.643 582.876L33.7142 615.461L23.8831 588.015L114.812 555.43L124.643 582.876Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M27.0272 512.737L102.128 499.019L107.363 527.698L32.2674 541.416L36.751 565.963L16.8332 569.604L2.63096 491.83L22.5488 488.19L27.0272 512.737Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M61.7858 419.782L99.8628 421.295L98.7023 450.429L61.883 448.967L0.999567 476.516L2.24191 445.286L37.2668 431.695L3.40755 415.891L4.5169 388.071L61.7858 419.782Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M138.374 289.053C134.642 298.027 129.959 303.048 123.349 305.844C124.566 315.027 124.208 323.413 119.617 334.446C110.532 356.269 97.0971 361.059 81.3049 354.484C66.7909 348.441 62.9105 333.879 63.1968 316.5C56.7757 317.528 49.148 317.195 43.5602 314.583C31.9704 309.045 27.8141 295.792 35.7383 276.757C42.7013 260.027 54.1633 255.41 64.9402 260.605C75.2519 265.61 75.8909 277.825 76.5095 289.749L98.0633 287.059C93.1299 279.6 86.5348 272.871 78.9634 267.16L86.2793 249.581C95.5173 256.842 108.344 270.862 115.64 284.426C120.133 283.45 122.552 281.895 123.661 279.231C124.77 276.568 124.121 273.873 119.627 271.577C115.895 269.742 112.378 268.704 110.205 268.366L116.008 254.423C118.682 254.541 123.099 255.385 128.456 257.9C140.281 263.529 144.785 273.658 138.374 289.053ZM79.5973 314.087C80.5994 320.191 83.5288 325.253 89.1166 327.866C96.6267 331.42 102.941 328.362 106.172 320.6C107.736 316.842 108.053 313.13 107.588 309.669L79.5973 314.087ZM48.3607 282.442C46.6941 286.446 47.6757 289.979 50.9936 291.503C53.8463 292.832 58.1152 293.047 63.156 291.733C63.1406 284.897 60.6867 280.32 56.7246 278.388C53.8718 277.058 50.0222 278.444 48.3607 282.442Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M98.0938 170.097L118.293 149.072L144.136 173.911L162.786 154.497L136.943 129.658L157.234 108.541L226.88 175.47L206.589 196.587L177.3 168.44L158.65 187.854L187.939 216.001L167.74 237.025L98.0938 170.097Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M279.139 142.702L267.554 132.711L246.225 145.907L250.213 160.597L227.989 174.346L202.187 76.7188L227.534 61.0322L303.039 127.915L279.139 142.702ZM230.433 97.4058L240.55 127.235L253.055 119.499L230.433 97.4058Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M346.5 113.897L319.297 123.617L257.493 43.1237L286.061 32.9182L320.933 81.3636L315.366 22.4469L342.569 12.7271L346.5 113.897Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M377.982 105.703L361.827 10.4638L390.569 5.58609L406.724 100.825L377.982 105.703Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M472.878 98.6427L442.72 47.6204L441.764 98.0496L416.949 97.5792L418.784 1.00042L444.78 1.49636L475.051 53.3981L476.027 2.08949L500.843 2.55984L499.007 99.1387L472.878 98.6427Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M573.013 118.981L571.521 109.281C566.04 113.223 558.448 116.44 545.177 112.692C521.169 105.912 506.961 88.5637 516.465 54.9152C526.378 19.8096 547.39 9.67058 573.801 17.1304C600.84 24.7692 607.22 37.9402 604.015 52.1952C603.207 56.0197 601.627 58.709 600.426 60.0691L575.488 55.1198C576.219 53.499 577.022 51.6327 577.507 49.4188C578.724 44.1422 578.55 37.0352 570.207 34.6781C559.716 31.7177 552.584 39.111 545.78 63.1982C540.172 83.0519 542.068 93.2574 552.932 96.3251C562.155 98.9328 566.945 94.5305 570.043 82.5968L553.995 78.0616L558.745 61.2349L600.319 72.9793L586.27 122.729L573.013 118.981Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M688.922 94.1735L678.948 108.991L707.051 127.914L695.88 144.506L667.778 125.582L646.285 157.508L622.104 141.228L676.049 61.0977L731.059 98.136L719.75 114.932L688.922 94.1735Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M707.358 211.285C686.351 188.681 689.572 173.49 704.224 159.875L752.127 115.346L771.973 136.703L725.363 180.03C718.641 186.283 715.476 191.192 721.912 198.12C729.244 206.009 734.847 201.878 740.649 196.478L787.264 153.151L807.11 174.508L759.207 219.037C744.55 232.653 727.291 232.74 707.358 211.285Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M767.969 296.666L797.683 245.389L753.246 269.246L741.508 247.377L826.608 201.688L838.909 224.599L808.48 276.409L853.689 252.138L865.427 274.006L780.326 319.695L767.969 296.666Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M781.676 329.212L803.332 322.197L811.502 347.434L789.846 354.449L781.676 329.212Z"
      stroke="white"
      strokeMiterlimit="10"
    />
  </svg>
)

export default Fun
