import React from 'react'
import PropTypes from 'prop-types'

import HorizontalImage from './HorizontalImage'

const uniKey = require('uuid/v1')

const Horizontal = ({
  id,
  images,
  title,
  titleColor,
  textSlideTitle,
  textSlideDescription,
  textSlideDescription2,
  textSlideLabel,
  textSlideSpeed,
  textSlideLerp,
  height,
  width,
  pad,
  bg,
}) => (
  <>
    <div id={id}>
      <div className="rel h100 w100 z2">
        <div className="h100 ch">
          <div className="rel w100">
            <div data-scroll data-scroll-sticky data-scroll-target={`#${id}`}>
              {title ? (
                <div className="abs top left fill z1 ch chi">
                  <div className="container">
                    <div className="row">
                      <div className="cl-sm-12">
                        <div className="c-xxs">
                          <h2 className={`tc-xxs ${titleColor} multiply rel z2`}>{title}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className={`row ${bg}`}>
                <div className={`cl-sm-${width || '12'}`}>
                  <ul className="slider h100">
                    {images.map((image, index) => (
                      <HorizontalImage key={uniKey()} image={image} pad={pad} id={id} />
                    ))}
                    {textSlideTitle ? (
                      <li
                        className="flex-end"
                        data-scroll
                        data-scroll-direction="horizontal"
                        data-scroll-target={`#${id}`}
                        data-scroll-speed={textSlideSpeed}
                        data-scroll-delay={textSlideLerp}
                      >
                        <div className="pb-sm-4">
                          <div className="row">
                            <div className="cl-sm-4">
                              <h3>{textSlideTitle}</h3>
                            </div>
                            <div className="cl-sm-8">
                              <p>{textSlideDescription}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    ) : (
                      ''
                    )}
                    {textSlideDescription2 ? (
                      <li
                        className="flex-end"
                        data-scroll
                        data-scroll-direction="horizontal"
                        data-scroll-target={`#${id}`}
                        data-scroll-speed={textSlideSpeed}
                        data-scroll-delay={textSlideLerp}
                      >
                        <div className="pb-sm-4">
                          <div className="row mln-sm-6 mrn-sm-6">
                            <div className="cl-sm-6">
                              <p>{textSlideDescription}</p>
                            </div>
                            <div className="cl-sm-6">
                              <p>{textSlideDescription2}</p>
                            </div>
                            <div className="abs top left100 h-100 w-30 ml-sm-6">
                              <p className="f7">{textSlideLabel}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {height ? <div className={`rel h${height}00 w100 z1`} /> : ''}
    </div>
  </>
)

Horizontal.propTypes = {
  id: PropTypes.string,
  images: PropTypes.array,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  textSlideTitle: PropTypes.string,
  textSlideDescription: PropTypes.string,
  textSlideDescription2: PropTypes.string,
  textSlideLabel: PropTypes.string,
  textSlideSpeed: PropTypes.string,
  textSlideLerp: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  pad: PropTypes.string,
  bg: PropTypes.string,
}

export default Horizontal
