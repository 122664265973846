import React from 'react'

import Layout from '../comps/Layout'
import SEO from '../comps/Layout/seo'
import Sixties from '../sections/sixties'
import Seventies from '../sections/seventies'
import Eighties from '../sections/eighties'
import Nineties from '../sections/nineties'
import Timeline from '../sections/timeline'

const IndexPage = () => (
  <Layout>
    <SEO title="History of Hip Hop" />
    <Sixties />
    <Seventies />
    <Eighties />
    <Nineties />
  </Layout>
)

export default IndexPage
