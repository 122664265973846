import React from 'react'
import PropTypes from 'prop-types'

import Image from './Elements/Image'

const Fader = ({ src, alt, delay, id, col, text, description, color }) => (
  <div className="abs top left fill z2" data-scroll data-scroll-call="fader" appear-delay={delay}>
    <div data-scroll data-scroll-sticky data-scroll-target={`#${id}`}>
      <div className="appearer full-height-img">
        {text ? (
          <div className="container rel w100 h100">
            <div className="row center-xxs middle-xxs fill abs">
              <div className={`cl-sm-${col} tc-xxs ${color}`}>
                <h3>{description}</h3>
              </div>
            </div>
          </div>
        ) : (
          <Image src={src} alt={alt} />
        )}
      </div>
    </div>
  </div>
)

Fader.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  delay: PropTypes.string,
  id: PropTypes.string,
  text: PropTypes.bool,
  col: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
}

export default Fader
