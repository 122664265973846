import React from 'react'
import PropTypes from 'prop-types'

import NewEra from '../comps/NewEra'
import PosterHover from '../comps/PosterHover'

import crossBronxPoster from '../images/cross-bronx-poster.jpg'
import cornbreadPoster from '../images/cornbread-poster.jpg'
import grandmasterFlowersPoster from '../images/grandmaster-flowers-poster.jpg'
import taki183Poster from '../images/taki-183-poster.jpg'
import rockdancePoster from '../images/rockdance-poster.jpg'

const Sixties = ({ x, y }) => (
  <>
    <div className="section rel z2" data-scroll-section>
      <div className="rel">
        <div id="intro-60s" className="abs top left z3 w100" data-scroll data-scroll-call="new-era">
          <div className="rel z2" data-scroll data-scroll-sticky data-scroll-target="#intro-60s">
            <div className="bg-grey texture rel white">
              <NewEra
                bg={{
                  bgSrc: 'hero-prequel-bg.png',
                  bgAlt: 'The Prequel',
                }}
                fg={{
                  fgSrc: 'hero-prequel-fg.png',
                  fgAlt: 'The Prequel',
                }}
                chapter="Chapter 01"
                title="The Prequel"
                year={{
                  yearSrc: 'year-60s.png',
                  yearAlt: "60's",
                }}
              />
            </div>
          </div>
          <div className="rel h100 w100" />
        </div>
        <div className="rel h200 w100" />
      </div>
    </div>
    <div className="section bg-teal texture pt-sm-14 pb-sm-8" data-scroll-section>
      <div className="container">
        <div className="row">
          <PosterHover
            x={x}
            y={y}
            items={[
              {
                img: crossBronxPoster,
                title: 'The Cross Bronx Expressway',
                date: '1963',
              },
              {
                img: cornbreadPoster,
                title: 'Cornbread',
                date: '1965',
              },
              {
                img: grandmasterFlowersPoster,
                title: 'Grandmaster Flowers',
                date: '1968',
              },
              {
                img: taki183Poster,
                title: 'Taki 183',
                date: '1969',
              },
              {
                img: rockdancePoster,
                title: 'Rockdance',
                date: 'Late 60s',
              },
            ]}
          />
        </div>
      </div>
    </div>
  </>
)

Sixties.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
}

export default Sixties
