import React, { useEffect, useState, useRef, createRef } from 'react'
import PropTypes from 'prop-types'

const PosterHover = ({ items }) => {
  const [currentHover, setCurrentHover] = useState('')
  const hoverTitlesWrap = useRef(null)
  const hoverTitles = useRef(items.map(() => createRef()))

  const [x, setX] = useState(0)
  const [y, setY] = useState(0)
  const trackMouse = e => {
    setX(e.clientX)
    setY(e.clientY)
  }

  useEffect(() => {
    hoverTitles.current.forEach(title => {
      const { current } = title
      current.addEventListener('mouseover', () => {
        setCurrentHover(current.getAttribute('data-img'))
      })
    })

    hoverTitlesWrap.current.addEventListener('mousemove', trackMouse)

    return () => {
      hoverTitlesWrap.removeEventListener('mousemove', trackMouse)
    }
  }, [])

  return (
    <div className="cl-sm-10 hover-title">
      <div className="hover-titles-wrap" id="hoverTitlesWrap" ref={hoverTitlesWrap}>
        <div className="hover-image">
          <img
            src={currentHover}
            alt="Poster Hover"
            style={{
              transform: `translate(${x}px, ${y}px)`,
            }}
          />
        </div>
        {items.map((item, i) => (
          <h4
            key={i}
            ref={hoverTitles.current[i]}
            className="line-s f1"
            data-img={item.img}
            data-scroll
            data-scroll-call="stagger-in"
          >
            {item.title} <span className="di">{item.date}</span>
          </h4>
        ))}
      </div>
    </div>
  )
}

PosterHover.propTypes = {
  items: PropTypes.array,
}

export default PosterHover
