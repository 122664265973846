import React from 'react'
import PropTypes from 'prop-types'

import Image from '../comps/Elements/Image'
import ImageLoad from '../comps/Elements/ImageLoad'
import AlbumThumb from '../comps/Elements/AlbumThumb'
import AlbumList from '../comps/Elements/AlbumList'
import VideoThumb from '../comps/Elements/VideoThumb'

import NewEra from '../comps/NewEra'
import RevealLayered from '../comps/RevealLayered'
import Horizontal from '../comps/Horizontal'
import Fader from '../comps/Fader'
import PosterHover from '../comps/PosterHover'
import GraffitiWall from '../comps/GraffitiWall'

import Fun from '../icons/Fun'

import delightL from '../images/rappers-delight-left.png'
import delightR from '../images/rappers-delight-right.png'
import ghettoBrosL from '../images/ghetto-bros-left.png'
import ghettoBrosR from '../images/ghetto-bros-right.png'

import grandmasterFlashPoster from '../images/grandmaster-flash-poster.jpg'
import grandwizzardPoster from '../images/grandwizzard-poster.jpg'
import boogalooPoster from '../images/boogaloo-poster.jpg'
import rocksteadycrewPoster from '../images/rocksteadycrew-poster.jpg'
import discoPoster from '../images/disco-poster.jpg'

const Seventies = ({ x, y }) => (
  <>
    <div className="section rel z1" data-scroll-section>
      <div className="rel z2">
        <div id="intro-70s" className="abs top left z3 w100" data-scroll data-scroll-call="new-era">
          <div className="rel z2" data-scroll data-scroll-sticky data-scroll-target="#intro-70s">
            <div className="bg-teal texture white">
              <div className="gap bg-teal texture" />
              <NewEra
                bg={{
                  bgSrc: 'hero-birth-bg.png',
                  bgAlt: 'Birth of Hip Hop',
                }}
                fg={{
                  fgSrc: 'hero-birth-fg.png',
                  fgAlt: 'Birth of Hip Hop',
                }}
                chapter="Chapter 02"
                title="The Birth"
                year={{
                  yearSrc: 'year-70s.png',
                  yearAlt: "70's",
                }}
              />
            </div>
          </div>
          <div className="rel h200 w100" />
        </div>

        <div id="bronx" className="abs top left z2 w100">
          <div className="rel z2" data-scroll data-scroll-sticky data-scroll-target="#bronx">
            <div className="bg-grey white">
              <RevealLayered
                bg={{
                  bgSrc: 'hero-birth-2.png',
                  bgAlt: 'Bronx on Fire',
                }}
                fg={{
                  fgSrc: 'the-bronx-is-burning.png',
                  fgAlt: 'The Bronx is Burning',
                }}
              />
            </div>
          </div>
          <div className="abs fill z3">
            <Fader src="hero-birth-texture.png" alt="" delay="3" id="bronx" />
            <Fader src="hero-birth-texture-2.png" alt="" delay="4" id="bronx" />
            <Fader
              text
              description="80% of the housing stock was destroyed in raging fires that swept across the South Bronx, averaging to 40 fires a day."
              delay="5"
              id="bronx"
              col="5"
              color="white"
            />
          </div>
          <div className="rel h600 w100" />
        </div>

        <div id="gangs-wrap" className="abs top left z1 w100">
          <div data-scroll data-scroll-sticky data-scroll-target="#gangs-wrap">
            <div className="black rel h1900">
              <Horizontal
                id="gangs"
                images={[
                  {
                    src: `youth-gangs-1.png`,
                    alt: `Photo by Robert R. McElroy / Getty Images`,
                    speed: `7`,
                    position: 'flex-end',
                    lerp: `0.1`,
                  },
                  {
                    src: `youth-gangs-2.png`,
                    alt: `Photo by Alejandro Olivera`,
                    speed: `7.3`,
                    position: 'flex-start',
                    lerp: `0.2`,
                  },
                  {
                    src: `youth-gangs-3.png`,
                    alt: `Photo by John Shearer — Time & Life Pictures/Getty Images`,
                    speed: `7.5`,
                    lerp: `0.3`,
                  },
                  {
                    src: `youth-gangs-4.png`,
                    alt: `Photo by Jean-Pierre Laffont`,
                    speed: `7.8`,
                    position: 'flex-start',
                    lerp: `0.4`,
                  },
                  {
                    src: `youth-gangs-5.png`,
                    alt: `Writer's Bench Music`,
                    speed: `8`,
                    position: 'flex-end',
                    lerp: `0.3`,
                  },
                  {
                    src: `youth-gangs-6.png`,
                    alt: `Photo by Jean-Pierre Laffont`,
                    speed: `8.2`,
                    position: 'flex-start',
                    lerp: `0.2`,
                  },
                  {
                    src: `youth-gangs-7.png`,
                    alt: `Image from Flying Cut Sleeves Documentary`,
                    speed: `8.5`,
                    lerp: `0.1`,
                  },
                ]}
                title="Youth Gangs"
                titleColor="white"
                textSlideTitle="1971 Bronx Treaty"
                textSlideDescription="It took the killing of Black Benjy, a gang peacemaker from the Ghetto Brothers, before representatives of 42 gangs came together to sign a peace treaty on December 7, 1971. Although the treaty did not eliminate gang violence altogether, it helped lead the way to the birth of Hip-Hop culture."
                textSlideSpeed="8"
                textSlideLerp="0.2"
                height="18"
                width="6"
                bg="bg-grey texture"
              />
            </div>
          </div>
          <div
            className="abs top right z4 w50 h100"
            data-scroll
            data-scroll-call="paste"
            paste-delay="16"
          >
            <div data-scroll data-scroll-sticky data-scroll-target="#gangs-wrap">
              <div className="w50 bg-tan texture paster pb-sm-2">
                <img src={ghettoBrosR} alt="Ghetto Brothers" />
              </div>
            </div>
          </div>

          <div
            className="abs top left z5 w50 h100"
            data-scroll
            data-scroll-call="paste"
            paste-delay="17"
            paste-speed="2"
          >
            <div data-scroll data-scroll-sticky data-scroll-target="#gangs-wrap">
              <div className="w50 bg-tan texture paster pb-sm-2">
                <img src={ghettoBrosL} alt="Ghetto Brothers" />
              </div>
            </div>
          </div>
        </div>

        <div className="rel h900 w100" />
        <div className="rel h1000 w100 bg-tan texture" />
        <div className="rel h50 w100 bg-tan texture">
          <div className="gap bg-tan texture" />
        </div>
      </div>
    </div>
    <div data-scroll-section id="graffiti-wall">
      <div className="rel z3">
        <div className="bg-tan texture white pb-sm-8 abs z3">
          <div className="gap bg-tan texture" />
          <Horizontal
            id="locations"
            images={[
              {
                src: `don-campbellock.png`,
                alt: `Don Campbellock Music`,
                main: `Don Campbellock`,
                pre: `1970`,
                post: `Los Angeles`,
                speed: `5.3`,
                url: `https://www.youtube.com/watch?v=OHu_NCu0_8M&`,
              },
              {
                src: `dj-hollywood.png`,
                alt: `DJ Hollywood Music Music`,
                main: `DJ Hollywood`,
                pre: `1971`,
                post: `Manhattan`,
                speed: `5.7`,
                url: `https://www.youtube.com/watch?v=EqLL9Tw2i6c`,
              },
              {
                src: `writers-bench.png`,
                alt: `Writer's Bench Music`,
                main: `Writer's Bench 149th Street`,
                pre: `1972`,
                post: `Bronx`,
                speed: `6.1`,
                url: `https://www.at149st.com/bench.html`,
              },
            ]}
            height="8"
            width="4"
            pad="18"
            bg="bg-tan texture"
          />
        </div>
        <GraffitiWall delay={6} />
      </div>
      <div className="rel h1000 w100" />
    </div>

    <div className="section bg-pink texture pt-sm-14" data-scroll-section>
      <div className="rel">
        <div id="birth-of-hip-hop" className="abs top left z3 w100">
          <div
            className="rel bg-pink texture pb-sm-8"
            data-scroll
            data-scroll-sticky
            data-scroll-target="#birth-of-hip-hop"
          >
            <div className="gap bg-pink texture" />
            <div className="f-headline">Aug 11, 1973</div>
            <div className="f-headline white">The Birth of Hip-Hop</div>
            <div className="abs right bottom w-30 mbn-sm-2">
              <Image src="kool-herc.png" alt="Kool Herc" />
              <div className="abs bottom left w-100">
                <div data-scroll data-scroll-call="rotate">
                  <div className="rotate-el">
                    <Image src="dj-kool-herc-label.png" alt="Kool Herc Label" />
                  </div>
                </div>
                <div data-scroll data-scroll-call="rotate" rotate-dir="left">
                  <div className="rotate-el rotate-left">
                    <Image src="dj-kool-herc-label.png" alt="Kool Herc Label" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="park-jam" className="abs top left z2 w100">
          <div className="rel z2" data-scroll data-scroll-sticky data-scroll-target="#park-jam">
            <Image src="park-jam.png" alt="Dj at the park" />
          </div>
          <div className="rel h200 w100" />
        </div>

        <div id="kool-herc" className="abs top left z1 w100">
          <div className="rel z2" data-scroll data-scroll-sticky data-scroll-target="#kool-herc">
            <div className="h100 ch">
              <div className="container">
                <div className="row center-xxs">
                  <div className="cl-sm-6 tc-xxs">
                    <h3 className="py-sm-14">
                      DJ Kool Herc (known as the father of Hip-Hop) deejayed a back-to-school party
                      for his sister, Cindy, which was held at 1520 Sedgwick Avenue in the
                      recreational room, on the west side of the Bronx, New York City.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rel h400 w100" />
        </div>
        <div className="rel h500 w100" />
      </div>
    </div>

    <div className="section bg-pink texture px-sm-2" data-scroll-section>
      <div className="gap bg-pink texture" />
      <div className="container">
        <div className="row end-xxs">
          <div className="cl-sm-5">
            <div className="rel">
              <ImageLoad
                src="1520-sedgwick.png"
                alt="1520 Sedgwick Location"
                speed="1"
                delay="0.05"
              />
              <div className="abs z2 w-80 left bottom mln-sm-20 mtn-sm-8">
                <ImageLoad src="kool-herc-party-flyer.png" alt="Kool Herc Party Flyer" speed="5" />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-sm-24 pb-sm-18">
          <div className="row">
            <div className="cl-sm-2 os-sm-1">
              <VideoThumb
                src="album-kool-herc.png"
                alt="Kool Herc Album"
                label="Kool Herc"
                url="https://www.youtube.com/watch?v=7qwml-F7zKQ"
              />
            </div>
            <div className="cl-sm-5 os-sm-1">
              <div data-scroll data-scroll-speed="2">
                <h3>The Get Down</h3>
                <p>
                  It was there that Kool Herc utilized his method for keeping the music going at the
                  Breaks in the records (usually the solo drum section of a song). Because these
                  Breaks were relatively short, he extended them on two turntables. This became
                  known as the Merry-Go-Round technique.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="dont-stop" className="pb-sm-10">
        <div
          data-scroll
          data-scroll-speed="26"
          data-scroll-direction="horizontal"
          data-scroll-target="#dont-stop"
        >
          <div className="white pb-sm-14">
            <h4 className="f1 tr-xxs line-s">
              <span className="no-break">You rock and you don't stop</span>
            </h4>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="cl-sm-6">
            <ImageLoad src="coke-la-rock.png" alt="Coke La Rock, the Emcee" speed="2" />
          </div>
          <div className="cl-sm-5 os-sm-1" data-scroll data-scroll-speed="3">
            <h3>The Emcee</h3>
            <p>
              Coke La Rock, credited to be one of the first MCs on the mic, performed alongside Kool
              Herc. He would improvise on the mic with shout outs to friends (which were very
              similar to the Jamaican tradition of "toasting") and rhyme original phrases such as
              “you rock and you don't stop.” Original emcees focused more on working the crowd with
              call and response phrases as opposed to what we know it as today: rap music.
            </p>
          </div>
        </div>
        <div className="pt-sm-16 pb-sm-8">
          <div className="row">
            <div className="cl-sm-4" data-scroll data-scroll-speed="2">
              <h3>The Break Boys & Girls</h3>
              <p>
                Kool Herc and Coke La Rock, aka the Herculoids, noticed the dancers who would get
                down on the Breaks of the records and labeled them as “b-boys” or “break boys” on
                the mic. Breaking becomes the official dance of Hip-Hop.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="section bg-grey" data-scroll-section>
      <div className="rel">
        <div className="gap bg-pink texture" />
        <div id="block-party" className="bg-pink texture">
          <div
            data-scroll
            data-scroll-sticky
            data-scroll-target="#block-party"
            data-scroll-call="spin"
            className="rel z3"
          >
            <div className="container">
              <div className="row center-xxs h100 ch">
                <div className="cl-sm-8">
                  <Fun />
                </div>
              </div>
            </div>
          </div>
          <div className="mtn-sm-40" data-scroll id="circle-lock">
            <div className="container">
              <div className="row center-xxs" data-scroll data-scroll-speed="6">
                <div className="cl-sm-6">
                  <ImageLoad src="block-party-1.png" alt="Close up of Kids Breaking" />
                </div>
              </div>
              <div className="row py-sm-8 center-xxs" data-scroll data-scroll-speed="2">
                <div className="cl-sm-3 tc-xxs">
                  <h3>The Bronx Block Party Era</h3>
                </div>
              </div>
              <div className="row end-xxs" data-scroll data-scroll-speed="6">
                <div className="cl-sm-5">
                  <ImageLoad src="block-party-3.png" alt="Breakdance Animation" />
                </div>
              </div>
              <div className="row pt-sm-4" data-scroll data-scroll-speed="5">
                <div className="cl-sm-4 os-sm-1">
                  <ImageLoad src="block-party-4.png" alt="DJ spinning turntables" />
                </div>
              </div>
              <div className="row center-xxs py-sm-16" data-scroll>
                <div className="cl-sm-4 tc-xxs">
                  <h3 className="w-50 dib">Hip-Hop Becomes a Culture</h3>
                  <p>
                    Everything leading up to this point is what unified all 4 elements (deejaying,
                    emceeing, breaking, and writing) to form this culture that is now globally known
                    as Hip-Hop. And it all started as a form of expression admist oppression and
                    displacement.
                  </p>
                  <div className="rel h200 w100" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="record" className="abs top200 left z4 w100" data-scroll data-scroll-call="roll">
          <div data-scroll data-scroll-sticky data-scroll-target="#record">
            <div className="wipe-cover bg-grey abs top w200 h200 z1" />
            <div className="container rel z2">
              <div className="row center-xxs">
                <div className="cl-sm-8">
                  <div className="roller">
                    <Image src="bongo-band.png" alt="Michael Viner's Incredible Bongo Band" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rel h800 w100" />
        </div>

        <div className="rel z5">
          <div className="container">
            <div className="row">
              <PosterHover
                x={x}
                y={y}
                items={[
                  {
                    img: grandmasterFlashPoster,
                    title: 'Grandmaster Flash',
                    date: '1975',
                  },
                  {
                    img: grandwizzardPoster,
                    title: 'Cornbread',
                    date: '1975',
                  },
                  {
                    img: boogalooPoster,
                    title: 'Boogaloo Sam',
                    date: '1975',
                  },
                  {
                    img: rocksteadycrewPoster,
                    title: 'Rock Steady Crew',
                    date: '1977',
                  },
                  {
                    img: discoPoster,
                    title: 'Disco Demolition Night',
                    date: '1979',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="section black bg-grey pt-sm-14 pb-sm-8" data-scroll-section>
      <div className="container">
        <div className="row pt-sm-12">
          <AlbumList
            albums={[
              {
                src: 'give-it-up.jpg',
                title: 'Give It Up Or Turnit A Loose',
                artist: 'James Brown',
                left: '10',
                speed: '2',
                url: 'https://www.youtube.com/watch?v=921kqkHOHDo',
              },
              {
                src: 'its-just-begun.jpg',
                title: "It's Just Begun",
                artist: 'The Jimmy Castor Bunch',
                left: '2',
                top: '8',
                speed: '1',
                url: 'https://www.youtube.com/watch?v=WylCcdTrzS4',
              },
              {
                src: 'apache.jpg',
                title: 'Apache',
                artist: 'Incredible Bongo Band',
                left: '6',
                pad: '2',
                speed: '6',
                url: 'https://www.youtube.com/watch?v=WY-Z6wm6TMQ',
              },
              {
                src: 'love-is-the-message.jpg',
                title: 'Love Is The Message',
                artist: 'MFSB',
                left: '1',
                speed: '4',
                url: 'https://www.youtube.com/watch?v=JkhKPaSlp4Q',
              },
              {
                src: 'rock-creek-park.jpg',
                title: 'Rock Creek Park',
                artist: 'The Blackbyrds',
                left: '9',
                top: '8',
                speed: '3',
                url: 'https://www.youtube.com/watch?v=ILrvgPkFuXI',
              },
              {
                src: 'rock-steady.jpg',
                title: 'Rock Steady',
                artist: 'Aretha Franklin',
                left: '4',
                top: '4',
                speed: '4',
                url: 'https://www.youtube.com/watch?v=_gWHD54GXmk',
              },
              {
                src: 'the-mexican.jpg',
                title: 'The Mexican',
                artist: 'Babe Ruth',
                left: '7',
                speed: '6',
                url: 'https://www.youtube.com/watch?v=B_yhy54RatQ',
              },
              {
                src: 'listen-to-me.jpg',
                title: 'Listen to Me',
                artist: 'Baby Huey & The Baby Sitters',
                left: '2',
                top: '4',
                speed: '4',
                url: 'https://www.youtube.com/watch?v=YJXMBxZgznY',
              },
              {
                src: 'expansions.jpg',
                title: 'Expansions',
                artist: 'Lonnie Liston Smith & The Cosmic Echoes',
                left: '10',
                top: '8',
                speed: '7',
                url: 'https://www.youtube.com/watch?v=T5R3_SbE7XQ',
              },
            ]}
          />
        </div>
      </div>
    </div>
    <div className="section bg-grey" data-scroll-section>
      <div id="fatback" className="rel z1">
        <div data-scroll data-scroll-call="expand" expand-multiply="2" expand-loc="center">
          <div data-scroll data-scroll-sticky data-scroll-target="#fatback">
            <div className="expander rel expand-top">
              <div className="abs fill">
                <Image src="fatback-band.png" alt="Fatback Band" />
              </div>
            </div>
          </div>
        </div>

        <div
          className="abs top left z2 w100 h100"
          data-scroll
          data-scroll-call="paste"
          paste-delay="2"
        >
          <div data-scroll data-scroll-sticky data-scroll-target="#fatback">
            <div className="h100 w100">
              <div className="ch">
                <div className="container">
                  <div className="row center-xxs">
                    <div className="cl-sm-5">
                      <div className="paster">
                        <Image src="fatback-band-album.png" alt="Fatback Band Album" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="abs top left z3 w50 h100"
          data-scroll
          data-scroll-call="paste"
          paste-delay="4"
        >
          <div data-scroll data-scroll-sticky data-scroll-target="#fatback">
            <div className="h100 w50 bg-black-full paster">
              <div className="ch">
                <div className="container rel z2">
                  <div className="row center-xxs">
                    <div className="cl-sm-9 white">
                      <h4 className="line-s pb-sm-2">
                        <span>1979</span> The Fatback Band
                        <span>King Tim III (Personality Jock)</span>
                      </h4>
                      <p>
                        The first ever rap record to chart on Billboards in 1979. Released on March
                        25, 1979, a few months before "Rapper's Delight."
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="abs top right z4 w50 h100"
          data-scroll
          data-scroll-call="paste"
          paste-delay="6"
        >
          <div data-scroll data-scroll-sticky data-scroll-target="#fatback">
            <div className="w50 bg-blue-lt texture paster pb-sm-2">
              <img src={delightR} alt="Sugar Hill - Rapper's Delight" />
            </div>
          </div>
        </div>

        <div
          className="abs top left z5 w50 h100"
          data-scroll
          data-scroll-call="paste"
          paste-delay="7"
        >
          <div data-scroll data-scroll-sticky data-scroll-target="#fatback">
            <div className="w50 bg-blue-lt texture paster pb-sm-2">
              <img src={delightL} alt="Sugar Hill - Rapper's Delight" />
            </div>
          </div>
        </div>

        <div className="rel w100 h1000" />
      </div>
      <div className="section bg-blue-lt texture pt-sm-10 pb-sm-10 rel z2">
        <div className="gap bg-blue-lt texture" />
        <div className="container">
          <div className="row middle-xxs">
            <div className="cl-sm-5 os-sm-1">
              <h4 className="line-s">
                <span>1979</span> Sugarhill Gang <span>Rapper's Delight</span>
              </h4>
              <p>
                The iconic record, selling more than 14 million copies, made rapping a viable
                commercial endeavor and created the rap business. Despite its international success
                and inestimable influence on future generations of rappers, "Rapper's Delight"
                remains one of the most controversial rap tracks. Not only was Chic's disco groove
                used without permission (Nile Rodgers and Bernard Edwards would eventually be
                credited as co-writers), but Big Bank Hank's verses - including the line "never let
                an MC steal your rhymes" - were allegedly stolen outright from Grandmaster Caz of
                the Bronx hip-hop group Cold Crush Brothers.
              </p>
              <div className="f7">
                Lynch, Joe. “35 Years Ago, Sugarhill Gang's 'Rapper's Delight' Made Its First Chart
                Appearance” 13, Oct. 2014
              </div>
            </div>
            <div className="cm-sm-2 os-sm-2">
              <ul className="stagger">
                <AlbumThumb
                  src="rappers-delight-album.png"
                  title="Rapper's Delight"
                  artist="Sugarhill Gang"
                  left="0"
                  speed="4"
                  col
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

Seventies.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
}

export default Seventies
