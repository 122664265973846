import React from 'react'
import PropTypes from 'prop-types'

import ImageLoad from './ImageLoad'
import Play from '../../icons/Play'

const LinkImage = ({ src, alt, url }) => (
  <>
    {url ? (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <ImageLoad src={src} alt={alt} />
      </a>
    ) : (
      <ImageLoad src={src} alt={alt} />
    )}
  </>
)

const LinkVideo = ({ url, src }) => (
  <>
    {url ? (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className="img-clip" data-scroll data-scroll-call="img-load" data-scroll-delay="1">
          <div className="img-scale">
            <video src={src} autoPlay loop muted />
          </div>
        </div>
      </a>
    ) : (
      <div className="img-clip" data-scroll data-scroll-call="img-load" data-scroll-delay="1">
        <div className="img-scale">
          <video src={src} autoPlay loop muted />
        </div>
      </div>
    )}
  </>
)

const VideoThumb = ({ src, alt, label, video, url }) => (
  <div className="video-thumb">
    <div className="video-img">
      {video ? <LinkVideo src={src} url={url} /> : <LinkImage src={src} alt={alt} url={url} />}
      <Play />
    </div>
    <h5 className="white">{label}</h5>
  </div>
)

LinkVideo.propTypes = {
  src: PropTypes.string,
  url: PropTypes.string,
}

LinkImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  url: PropTypes.string,
}

VideoThumb.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  label: PropTypes.string,
  video: PropTypes.bool,
  url: PropTypes.string,
}

export default VideoThumb
