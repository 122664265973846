import React from 'react'
import PropTypes from 'prop-types'

import Image from './Elements/Image'

const NewEra = ({ bg, fg, chapter, title, year }) => {
  const { bgSrc, bgAlt } = bg
  const { fgSrc, fgAlt } = fg
  const { yearSrc, yearAlt } = year

  return (
    <>
      <div className="fade-pre">
        <div className="abs top left fill z2 ch chi">
          <div className="container">
            <div className="row center-xxs middle-xxs fill abs">
              <div className="c-sm-5 tc-xxs">
                <h2 className="f-xl fade-chapter line-s">
                  <span>{chapter}</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="scale-clip">
        <div className="scale-bg">
          <div className="rel z1 w100 h100 ch chi">
            <Image src={bgSrc} alt={bgAlt} />
          </div>
        </div>
        <div className="scale-fg abs top left fill z3">
          <div className="ch chi">
            <Image src={fgSrc} alt={fgAlt} />
          </div>
        </div>
        <div className="fade-year">
          <div className="abs top left fill z2 ch chi">
            <Image src={yearSrc} alt={yearAlt} />
          </div>
        </div>
        <div className="fade-title">
          <div className="abs top left fill z4 ch">
            <div className="container">
              <div className="row center-xxs middle-xxs fill abs">
                <div className="c-sm-5 tc-xxs">
                  <h1 className="fade-name">{title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

NewEra.propTypes = {
  bg: PropTypes.object,
  fg: PropTypes.object,
  chapter: PropTypes.string,
  year: PropTypes.object,
  title: PropTypes.string,
}

export default NewEra
