import React from 'react'
import PropTypes from 'prop-types'

import Image from './Image'

const ImageLoad = ({ src, alt, speed, delay, video, norepeat }) => {
  const timingEffects = {
    'data-scroll-speed': speed,
    'data-scroll-delay': delay,
  }

  return (
    <div
      className="img-clip"
      data-scroll
      data-scroll-call="img-load"
      data-scroll-delay="1"
      data-scroll-repeat={!norepeat}
      {...timingEffects}
    >
      <div className="img-scale">
        {video ? <video src={src} autoPlay loop muted /> : <Image src={src} alt={alt} />}
      </div>
    </div>
  )
}

ImageLoad.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  speed: PropTypes.string,
  delay: PropTypes.string,
  video: PropTypes.bool,
}

export default ImageLoad
