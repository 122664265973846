import React from 'react'
import PropTypes from 'prop-types'

import Image from './Elements/Image'

const Appear = ({ src, alt, col, width, left, top, pad, delay, id }) => (
  <div
    className={`abs ${width ? `gw-${width} gl-${left} gt-${top}` : `top left w100 h100 z1`}`}
    data-scroll
    data-scroll-call="appear"
    appear-delay={delay}
  >
    <div data-scroll data-scroll-sticky data-scroll-target={`#${id}`}>
      <div className={width ? '' : `ch h100`}>
        <div className={width ? '' : `container`}>
          <div className="row center-xxs">
            <div className={`cl-sm-${col || '12'}`}>
              <div className={`appearer ${pad ? `px-sm-${pad}` : ''}`}>
                <Image src={src} alt={alt} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

Appear.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  col: PropTypes.string,
  pad: PropTypes.string,
  width: PropTypes.string,
  left: PropTypes.string,
  top: PropTypes.string,
  delay: PropTypes.string,
  id: PropTypes.string,
}

export default Appear
