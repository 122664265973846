import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Image = ({ src, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { regex: "/png|jpg/" } }) {
        edges {
          node {
            id
            base
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const queriedImg = data.allFile.edges.filter(function(single) {
    return single.node.base === src
  })

  return (
    <Img
      fluid={queriedImg[0].node.childImageSharp.fluid}
      fadeIn
      durationFadeIn={2000}
      loading="lazy"
      alt={alt}
    />
  )
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
}

export default Image
