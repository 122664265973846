import React from 'react'
import PropTypes from 'prop-types'

import Image from './Elements/Image'

const ImageWrap = ({ image }) => (
  <>
    {image.main ? (
      <h4 className="line-s">
        <span>{image.pre}</span> {image.main} <span>{image.post}</span>
      </h4>
    ) : (
      ''
    )}
    <div className="hover-clip">
      <div className="hover-scale">
        <Image src={image.src} alt={image.alt} />
      </div>
    </div>
  </>
)

const HorizontalImage = ({ image, pad, id }) => (
  <li
    className={`${image.position ? `${image.position} ` : `flex-center `} ${
      pad ? `mr-sm-${pad} ` : ``
    } ${image.pad ? `px-sm-${image.pad} ` : ``}`}
    data-scroll
    data-scroll-direction="horizontal"
    data-scroll-target={`#${id}`}
    data-scroll-speed={image.speed}
    data-scroll-delay={image.lerp}
  >
    {image.url ? (
      <a href={image.url} target="_blank" rel="noopener noreferrer" className="img-hover">
        <ImageWrap image={image} />
      </a>
    ) : (
      <ImageWrap image={image} />
    )}
  </li>
)

ImageWrap.propTypes = {
  image: PropTypes.object,
}

HorizontalImage.propTypes = {
  image: PropTypes.object,
  pad: PropTypes.string,
  id: PropTypes.string,
}

export default HorizontalImage
