import React from 'react'

import Image from '../comps/Elements/Image'
import ImageLoad from '../comps/Elements/ImageLoad'

import NewEra from '../comps/NewEra'
import Fader from '../comps/Fader'
import AlbumList from '../comps/Elements/AlbumList'

const Nineties = () => (
  <>
    <div className="section" data-scroll-section>
      <div className="rel">
        <div id="intro-90s" className="abs top left z3 w100" data-scroll data-scroll-call="new-era">
          <div className="rel z2" data-scroll data-scroll-sticky data-scroll-target="#intro-90s">
            <div className="bg-pink texture rel white">
              <div className="gap bg-pink texture" />
              <NewEra
                bg={{
                  bgSrc: 'hero-golden-age-bg.png',
                  bgAlt: 'The Golden Age',
                }}
                fg={{
                  fgSrc: 'hero-golden-age-fg.png',
                  fgAlt: 'The Golden Age',
                }}
                chapter="Chapter 04"
                title="The Golden Age"
                year={{
                  yearSrc: 'year-90s.png',
                  yearAlt: "90's",
                }}
              />
            </div>
          </div>
          <div className="rel h300 w100" />
        </div>
        <div className="rel h400 w100" />
      </div>
    </div>
    <div className="section bg-yellow rel pt-sm-24 pb-sm-14" data-scroll-section>
      <div className="container">
        <div className="row">
          <div className="cl-xxs-12">
            <div className="f-headline tc-xxs black-full caps">
              The Rise <div>of</div> Gangsta Rap & <div>The</div> <div>Boom</div> <div>Bap</div>{' '}
              Sound
            </div>
          </div>
        </div>
        <div className="row mt-sm-4" data-scroll data-scroll-speed="1">
          <div className="cl-sm-3 os-sm-3">
            <p>
              Gone was the primary focus on party records and making people dance and in its place
              entered the art form with independent journalism, poetry, and hard-hitting raps
              documenting life on America's dangerous streets.
            </p>
            <p className="f7">
              Williams, Stereo. “Is Hip-Hop Still ‘CNN For Black People?’” 14, Apr. 2017
            </p>
          </div>
        </div>
      </div>
      <div className="abs fill">
        <div className="container pt-sm-12">
          <div className="row" data-scroll data-scroll-speed="1">
            <div className="cl-sm-2 os-sm-9">
              <ImageLoad src="gangsta-1.jpg" />
            </div>
          </div>
          <div className="row" data-scroll data-scroll-speed="3">
            <div className="cl-sm-2 os-sm-5">
              <ImageLoad src="gangsta-2.jpg" />
            </div>
          </div>
          <div className="row" data-scroll data-scroll-speed="1">
            <div className="cl-sm-3 os-sm-2">
              <ImageLoad src="gangsta-3.jpg" />
            </div>
          </div>
          <div className="row" data-scroll data-scroll-speed="4">
            <div className="cl-sm-3 os-sm-9">
              <ImageLoad src="gangsta-4.jpg" />
            </div>
          </div>
          <div className="row" data-scroll data-scroll-speed="1">
            <div className="cl-sm-2 os-sm-8">
              <ImageLoad src="gangsta-5.jpg" />
            </div>
          </div>
          <div className="row" data-scroll data-scroll-speed="2">
            <div className="cl-sm-3 os-sm-1">
              <ImageLoad src="gangsta-6.jpg" />
            </div>
          </div>
          <div className="row" data-scroll data-scroll-speed="6">
            <div className="cl-sm-2 os-sm-9">
              <ImageLoad src="gangsta-7.jpg" />
            </div>
          </div>
          <div className="row" data-scroll data-scroll-speed="1">
            <div className="cl-sm-3 os-sm-2">
              <ImageLoad src="gangsta-8.jpg" />
            </div>
          </div>
          <div className="row" data-scroll data-scroll-speed="2">
            <div className="cl-sm-2 os-sm-8">
              <ImageLoad src="gangsta-9.jpg" />
            </div>
          </div>
          <div className="row mtn-sm-4" data-scroll data-scroll-speed="3">
            <div className="cl-sm-2 os-sm-1">
              <ImageLoad src="gangsta-10.jpg" />
            </div>
          </div>
          <div className="row mtn-sm-16" data-scroll data-scroll-speed="7">
            <div className="cl-sm-2 os-sm-6">
              <ImageLoad src="gangsta-11.jpg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="section bg-white texture yellow" data-scroll-section>
      <div id="east-vs-west" className="rel">
        <div className="rel z2">
          <div data-scroll data-scroll-sticky data-scroll-target="#east-vs-west">
            <div className="tc-xxs rel ch h100">
              <div className="abs fill z1">
                <div className="abs top left w-50 h-100 full-height-img">
                  <Image src="big.png" />
                </div>
                <div className="abs top right w-50 h-100 full-height-img">
                  <Image src="2pac.png" />
                </div>
              </div>
              <h3 className="f-banner rel z2 w100">
                <div
                  data-scroll
                  data-scroll-direction="horizontal"
                  data-scroll-target="#east-vs-west"
                  data-scroll-speed="-4.2"
                >
                  East
                </div>
                <div
                  data-scroll
                  data-scroll-direction="horizontal"
                  data-scroll-target="#east-vs-west"
                  data-scroll-speed="4.2"
                >
                  West
                </div>
              </h3>
            </div>
          </div>
        </div>
        <div className="rel h400 w100" />
      </div>
    </div>
    <div className="section bg-yellow pt-sm-10" data-scroll-section>
      <div className="container">
        <div className="row">
          <div className="cl-sm-12">
            <div className="py-sm-12 white">
              <h3 className="line-s f-headline tc-xxs">
                <span>1994</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="cl-sm-5">
            <div data-scroll data-scroll-speed="1">
              <h4 className="pb-sm-2">East Coast vs West Coast</h4>
              <div className="pr-sm-2">
                <p>
                  Tupac Shakur and Christopher Wallace (The Notorious B.I.G.) became two of
                  hip-hop’s most notable icons amid an infamous rivalry that continues to hold the
                  attention of fans more than two decades after their deaths.
                </p>
                <p>
                  There were many factors behind the complex New York vs. Los Angeles turf rivalry
                  that heightened between 1994 and 1997 and ultimatley lead to their deaths. The
                  beef had everything to do with competition among record labels, media coverage,
                  gang culture and two artists with a talent for rhythmic comebacks.
                </p>
                <div className="f7">
                  Giannotta, Meghan. “East Coast vs. West Coast rivalry: A look at Tupac and
                  Biggie’s infamous hip-hop feud” March 2019
                </div>
              </div>
            </div>
          </div>
          <div className="cl-sm-5 os-sm-1">
            <div data-scroll data-scroll-speed="5">
              <ImageLoad src="east-vs-west.png" />
            </div>
          </div>
        </div>
        <div className="row py-sm-10">
          <AlbumList
            albums={[
              {
                src: `scenario.jpg`,
                title: `Scenario`,
                artist: `A Tribe Called Quest`,
                left: `7`,
                speed: `2`,
                url: `https://www.youtube.com/watch?v=Q6TLWqn82J4`,
              },
              {
                src: `nystateofmind.jpg`,
                title: `N.Y. State of Mind`,
                artist: `Nas`,
                left: `2`,
                top: `4`,
                speed: `1`,
                url: `https://www.youtube.com/watch?v=hI8A14Qcv68`,
              },
              {
                src: `massappeal.jpg`,
                title: `Mass Appeal`,
                artist: `Gang Starr`,
                left: `10`,
                top: `5`,
                speed: `6`,
                url: `https://www.youtube.com/watch?v=y9lNbNGbo24`,
              },
              {
                src: `juicy.jpg`,
                title: `Juicy`,
                artist: `The Notorious B.I.G.`,
                left: `6`,
                top: `4`,
                speed: `4`,
                url: `https://www.youtube.com/watch?v=_JZom_gVfuw`,
              },
              {
                src: `cream.jpg`,
                title: `C.R.E.A.M.`,
                artist: `Wu-Tang Clan`,
                left: `2`,
                speed: `3`,
                url: `https://www.youtube.com/watch?v=PBwAxmrE194`,
              },
              {
                src: `ambitionz.jpg`,
                title: `Ambitionz Az A Ridah`,
                artist: `Tupac`,
                left: `7`,
                top: `2`,
                speed: `4`,
                url: `https://www.youtube.com/watch?v=cQZqPi1aHNo`,
              },
              {
                src: `itwasagoodday.jpg`,
                title: `It was a Good Day`,
                artist: `Ice Cube`,
                left: `4`,
                speed: `6`,
                url: `https://www.youtube.com/watch?v=h4UqMyldS7Q`,
              },
              {
                src: `nuthinbutagthang.jpg`,
                title: `Nuthin' But A G Thang`,
                artist: `Dr. Dre`,
                left: `9`,
                top: `4`,
                speed: `4`,
                url: `https://www.youtube.com/watch?v=cp0ON1288zE`,
              },
              {
                src: `passinmeby.jpg`,
                title: `Passin' Me By`,
                artist: `The Pharcycle`,
                left: `1`,
                top: `8`,
                speed: `7`,
                url: `https://www.youtube.com/watch?v=a-mAK3uB2_0`,
              },
            ]}
          />
        </div>
      </div>
    </div>
    <div className="section bg-yellow pt-sm-10" data-scroll-section>
      <div className="container">
        <div className="row">
          <div className="cl-sm-8">
            <h4 className="line-s">
              <span>Breaking died towards the end of the 80's but had a</span> resurgence in the
              90’s as Hip-Hop Festivals & Breaking Competitions started to be organized.
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div className="section bg-yellow" data-scroll-section>
      <div id="culture" className="rel z1">
        <div data-scroll data-scroll-call="expand" expand-multiply="2" expand-loc="center">
          <div data-scroll data-scroll-sticky data-scroll-target="#culture">
            <div className="expander rel expand-top">
              <div className="abs fill">
                <Image src="hip-hop-culture.jpg" alt="Freestyle" />
              </div>
            </div>
          </div>
        </div>
        <div className="abs fill z3">
          <div
            className="abs top left fill z2"
            data-scroll
            data-scroll-call="fader"
            appear-delay="4"
          >
            <div data-scroll data-scroll-sticky data-scroll-target="#culture">
              <div className="appearer">
                <div className="container ch h100 rel z2">
                  <div className="row center-xxs">
                    <div className="cl-sm-8">
                      <h4 className="tc-xxs white">
                        Hip-Hop is still celebrated across the <div>globe to this day.</div>
                      </h4>
                      <Image src="hip-hop-culture.png" alt="Hip - Hop Is A Culture" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rel h1100 w100" />
      </div>
    </div>
  </>
)

export default Nineties
